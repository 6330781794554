<template>
  <div class="p-10 pr-16 all">
    <div>
      <div class="mt-10 ">
        <facture  v-if="!selectedItem" @oga="retourItem" />
        <liste v-if="selectedItem" :donne="selectedItem.articles" @oga="selectedItem = null"/>
      </div>
    </div>

  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import search from '../../assets/icons/search.svg'
import liste from '../../component/cdevs/reception/liste'
import facture from '../../component/cdevs/reception/factures'

export default {
  name: "Index",

  components: {
    liste,
    facture
  },

  data () {
    return {
      icons: {
        back,
        search,
      },
      selectedItem: null
    }
  },

  created () {
  },

  methods: {
    retourItem (answer) {
      this.selectedItem = answer
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
}
</style>
