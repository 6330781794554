<template>
  <div>
    <devisFacture :activation="activeDevis" v-if="activeDevis" :donnes="donnes" @oga="retourDevis"/>

    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c36 items-center font-c6">
          <icon
              :data="icons.back"
              height="70"
              width="70"
              class="cursor-pointer"
              @click="$emit('oga', false)"
              original
          />
          <div class="">
            Reception de verres
          </div>
        </div>

        <div class="w-1/2">
          <div class="flex justify-end w-full">
<!--            <div class="w-1/2 mr-4">-->
<!--              <multiselect :icon="icons.filter" height="54px" background="white" :icon-width="25" value-t="En attente de validation" :option="filtre"/>-->
<!--            </div>-->

            <bouton v-if="donnes.length === 0" label="Validation" height="54px" background="#DDDDDD" color="#606060" radius="5px" class="w-1/3"/>
            <bouton v-if="donnes.length > 0" label="Validation" height="54px" color="white" radius="5px" class="w-1/3" @click="activeDevis = true"/>
          </div>
        </div>
      </div>

      <div class="mt-10">
        <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8 pl-8">
          <div class="mt-4">
            <div class="all">
              <div class="w-full flex items-center text-90 text-c16 font-c5 text-left mb-6">
                <div class="w-1/6 ml-4">
                  INFOS COMMERCIALES
                </div>
                <div class="w-1/4 ml-4">
                  CLIENT
                </div>
                <div class="w-20 ml-4">
                  OEIL
                </div>
                <div class="w-1/5 ml-4 text-left">
                  NOM FOURNISSEUR
                </div>
                <div class="w-1/6 ml-4">
                  NOM DU VERRE
                </div>
              </div>

              <div class="diviser" />

              <div
                  v-if="charge && donnes.length === 0"
                  class="flex justify-center mt-16"
              >
                <easy-spinner
                    type="dots"
                    size="70"
                />
              </div>

              <div
                  v-for="(item, index) in donnes"
                  :key="index"
                  class="mt-5"
              >
                <div
                    v-if="index > 0"
                    class="diviser mt-5"
                />
                <div class="flex text-left mt-6">
                  <div class="w-1/6 ml-4">
                    <div>{{ item.product.glassType}} - {{ item.product.glassMaterial}}</div>
                    <div>{{ item.product.meta.generation}}</div>
                  </div>

                  <div class="w-1/4 ml-4">
                    {{ item.estimate.client.surname }} {{ item.estimate.client.name }}
                  </div>
                  <div class="w-20 ml-4">
                    <span v-if="item.meta !== undefined && item.meta !== null">{{ item.meta.oeil }}</span>
                  </div>
                  <div class="w-1/5 ml-4 text-left">
                    {{ item.product.meta.supplier.brand }} {{ item.product.meta.supplier.indice }}

                    <!--                    traitement de masse -->
                    <span v-if="item.treatment !== undefined && item.treatment !== null">
                      <span v-for="(treat, id) in item.treatment" :key="id">
                        <span v-if="treat.product.meta">
                          <span v-if="treat.product.meta.intituleFournisseur !== undefined">
                             {{treat.product.meta.intituleFournisseur}}
                          </span>
                        </span>
                      </span>
                    </span>

                    <span v-if="item.meta !== null">
                      <span v-if="item.meta.treatmentData !== undefined && item.meta.treatmentData !== null"> {{ item.meta.treatmentData.color }}</span>
                    </span>
                  </div>
                  <div class="w-1/6 ml-4">
                    {{item.product.name}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/backBut.svg'
import search from '../../../assets/icons/search.svg'
import calendar from '../../../assets/icons/calendar.svg'
import filter from '../../../assets/icons/filter.svg'
import bouton from '../../helper/add/button'
import devisFacture from '../../popup/devisFacture'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",

  components: {
    bouton,
    devisFacture,
  },

  props: {
    donne: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      icons: {
        back,
        search,
        calendar,
        filter
      },
      donnes: [],
      activeClient: false,
      reloadSession: false,
      selectedItem: null,
      user: null,
      filtre: ['En attente de validation', 'Valider', 'Invalider'],
      devis: [],
      charge: false,
      activeDevis: false,
      allSelect: false,
      dataSelect: [],
      activeFacture: false,
      finalData: []
    }
  },

  created () {
    this.donnes = this.donne
  },

  methods: {
    retourDevis () {
      this.activeDevis = false
      this.$emit('oga', false)
    },

    // activeAll () {
    //   this.allSelect =! this.allSelect
    //   if (this.allSelect) {
    //     this.dataSelect = this.donnes
    //   } else {
    //     this.dataSelect = []
    //   }
    //   console.log(this.dataSelect)
    // },

    selected (item) {
      let exist = null
      for (let i in this.dataSelect) {
        if (this.dataSelect[i].id === item.id) {
          exist = i
          this.dataSelect.splice(i, 1)
        }
      }

      if (exist === null) {
        this.dataSelect.push(item)
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
