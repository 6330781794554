<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Conformité des verres
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white pr-6 pb-6">
            <div class="flex justify-center">
              <easy-spinner
                  v-if="charge"
                  type="dots"
                  size="40"
              />
            </div>


            <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8 pl-8">
              <div class="mt-4">
                <div class="all">
                  <div class="w-full flex items-center text-90 text-c15 font-c5 text-left mb-6">
                    <div class="w-1/6 ml-4">
                      REFERENCE
                    </div>
                    <div class="w-1/5">
                      CLIENT
                    </div>
                    <div class="w-20 ml-4">
                      OEIL
                    </div>
                    <div class="w-1/6 text-center">
                      NOM FOURNISSEUR
                    </div>
                    <div class="w-1/6 ml-4 text-center">
                      NOM DU VERRE
                    </div>
                    <div class="w-1/6 ml-4 text-center">
                      PRIX DE VENTE
                    </div>

                    <div class="w-1/6 ml-4 text-center">
                      ACTIONS
                    </div>
                  </div>

                  <div class="diviser" />

                  <div
                      v-if="charge"
                      class="flex justify-center mt-16"
                  >
                    <easy-spinner
                        type="dots"
                        size="70"
                    />
                  </div>

                  <div
                      v-for="(item, index) in devis"
                      :key="index"
                      class="mt-5"
                  >
                    <div
                        v-if="index > 0"
                        class="diviser mt-5"
                    />

                    <div class="flex text-left mt-6">
                      <div class="w-1/6 ml-4">
                        <span v-if="item.product.ref">{{ item.product.ref}}</span>
                      </div>
                      <div class="w-1/5">
                        {{ item.estimate.client.surname }} {{ item.estimate.client.name }}
                      </div>
                      <div class="w-20 ml-4">
                        <span v-if="item.meta !== undefined && item.meta !== null">{{ item.meta.oeil }}</span>
                      </div>
                      <div class="w-1/6 text-center">
                        {{ item.product.meta.supplier.brand }}
                      </div>
                      <div class="w-1/6 ml-4 text-center">
                        {{item.product.name}}
                      </div>
                      <div class="w-1/6 ml-4 text-center">
                        {{item.amount.toLocaleString()}}
                      </div>

                      <div class="w-1/6 ml-4 text-center flex">
                        <buton
                            v-if="item.articleTechState === 'NOT_DELIVERED' || item.articleTechState === 'CONFORM'"
                            label="Non conforme"
                            background="#F82626"
                            color="white"
                            size="9px"
                            radius="5px"
                            height="25px"
                            class="w-1/2 mr-2"
                            @click="selected(index, 'NOT_CONFORM')"
                        />
                        <buton
                            v-if="item.articleTechState === 'NOT_CONFORM'"
                            label="Conforme"
                            background="#FED4D4"
                            color="#F82626"
                            size="9px"
                            radius="5px"
                            height="25px"
                            class="w-1/2 mr-2"
                        />

                        <buton
                            v-if=" item.articleTechState === 'NOT_CONFORM'"
                            label="Conforme"
                            background="#2B9E7C"
                            color="white"
                            size="9px"
                            radius="5px"
                            height="25px"
                            class="w-1/2 mr-2"
                            @click="selected(index, 'CONFORM')"
                        />
                        <buton
                            v-if="item.articleTechState === 'CONFORM' || item.articleTechState === 'NOT_DELIVERED'"
                            label="Conforme"
                            background="#D5ECE5"
                            color="#2B9E7C"
                            size="9px"
                            radius="5px"
                            height="25px"
                            class="w-1/2 mr-2"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                      class="diviser mt-6"
                  />
                </div>
              </div>

              <div class="mt-10 flex justify-end">
                <buton
                    label="Enregistrer "
                    background="#2B9E7C"
                    color="#fff"
                    radius="5px"
                    height="55px"
                    class="w-1/3"
                    @oga="continuer"
                />
              </div>
            </div>

            <div
              v-if="error !== null"
              class="mt-6 text-red text-c13"
            >
              {{ error }}
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton,
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },

    donnes: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      date: '',
      error: null,
      charge: false,
      allInfo: [],
      position: '',
      devis: [],
      allSelect: false,
      dataSelect: []
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.devis = this.donnes
    console.log(this.devis)
  },

  methods: {

    fermer () {
      this.$emit('oga', false)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },

    continuer () {
      this.charge = true
      const data = []
      for (let item in this.devis) {
        const obj = {
          id: this.devis[item].id,
        }
        if (this.devis[item].articleTechState === 'NOT_DELIVERED' || this.devis[item].articleTechState === 'CONFORM') {
          obj.articleTechState = 'CONFORM'
        } else {
          obj.articleTechState = 'NOT_CONFORM'
        }
        data.push(obj)
      }


      http.post(apiroutes.baseURL + apiroutes.factureValidation, {
        articles: data
      })
          .then(response => {
            console.log('valider')
            console.log(response)
            this.$emit('oga', true)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    selected (index, status) {
      this.devis[index].articleTechState = status
    },
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 90%;
    }

    .success-container {
      width: 100%;
      background-color: #F5F5F5;
      max-height: 100vh;
      margin: auto;
      border-radius: 12px;
      overflow-y: scroll;
    }
    .diviser {
      height: 0.5px;
      background-color: #DDDDDD;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
    }
</style>
